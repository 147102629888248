<template>
  <main class="page-wrapper" :class="{ show: showPage }">
    <div ref="loadingOverlay" class="loading-overlay"></div>
    <slot />
  </main>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";

export default defineComponent({
  name: "PageWrapper",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState("page", {
      showPage: "showPage",
    }),
  },
  watch: {
    $route() {
      if (this.showPage) {
        // Toggle to false on route change.
        this.toggleShowPage();
        this.toggleLoadingOverlay(false);
      }
    },
    showPage(newVal) {
      // Wait for the css animation to complete and then hide the element.
      setTimeout(() => {
        this.toggleLoadingOverlay(newVal);
      }, 600);
    },
  },
  mounted() {
    this.toggleLoadingOverlay(this.showPage);
  },
  methods: {
    ...mapActions("page", {
      toggleShowPage: "toggleShowPage",
    }),
    toggleLoadingOverlay(hide = true) {
      const loadingOverlayEl = this.$refs.loadingOverlay;
      if (!loadingOverlayEl) return;

      if (hide) {
        loadingOverlayEl.classList.add("hidden");
        return;
      }
      loadingOverlayEl.classList.remove("hidden");
    },
  },
});
</script>

<style lang="scss" scoped>
.page-wrapper {
  @apply relative bg-white;
  min-height: 75vh;

  &.show .loading-overlay {
    @apply opacity-0;
    transition: all 0.7s linear 0.4s;
  }

  &:not(.show) {
    @apply overflow-y-hidden max-h-screen;
  }

  .loading-overlay {
    @apply absolute h-full w-full opacity-100 bg-white;
    z-index: 90;

    &.hidden {
      z-index: -1;
    }
  }
}
</style>
