<template>
  <div class="open-text-container">
    <div class="content-container">
      <component :is="headerAnimation" :threshold="1" animation-delay="0.4s"
        ><h2><slot name="header" /></h2
      ></component>

      <component :is="contentAnimation" :threshold="1" animation-delay="0.7s">
        <div class="content">
          <slot name="content" />
        </div>
      </component>

      <div v-if="$slots.buttonArea" class="button-area">
        <slot name="buttonArea" />
      </div>
    </div>
  </div>
</template>

<script>
import FadeIn from "@/components/UI/Animations/FadeIn";
export default {
  name: "OpenText",
  components: { FadeIn },
  props: {
    headerAnimation: {
      type: String,
      default: () => "FadeIn",
    },
    contentAnimation: {
      type: String,
      default: () => "FadeIn",
    },
  },
};
</script>

<style lang="scss" scoped>
.open-text-container {
  @apply py-16 pb-20;
  .content-container {
    h2 {
      @apply text-center pb-8;
      font-size: 2.68em;
      line-height: 1em;
    }
    .content {
      @apply text-center px-8;
      font-size: 1.57em;
      line-height: 1.4em;
    }
    .button-area {
      @apply flex justify-center pt-12;
    }
  }
}
</style>
