<template>
  <div class="footer-container">
    <div class="content-container">
      <div class="content">
        <div class="flex justify-evenly items-center w-full">
          <img
            src="@/assets/images/NBAA.png"
            alt="NBAA Member"
            style="max-width: 200px; max-height: 100px"
          />
          <img
            src="@/assets/images/ARGUSGold.png"
            alt="ARGUS Gold"
            style="max-width: 200px; max-height: 100px"
          />
        </div>
      </div>

      <div class="content">
        <div class="logo">
          <img src="@/assets/images/pvtair-footer-logo.png" alt="PvtAir Logo" />
        </div>
        <div class="address-details">
          <a href="https://maps.app.goo.gl/ZLdAYWE1gAv2NkCR8" target="_blank"
            >6095 E Rutter Ave Spokane, WA 99212</a
          >
        </div>
        <div class="contact-information">
          <a href="mailto:admin@pvtair.com">admin@pvtair.com</a>
          <a href="tel:+15095359011">(509) 535-9011</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterBlock",
};
</script>

<style lang="scss" scoped>
.footer-container {
  @apply bg-black py-6;

  .content-container {
    .content {
      @apply flex flex-col items-center;

      .logo img {
        min-width: 210px;
      }

      .address-details a,
      .contact-information a {
        @apply text-white;
        letter-spacing: 1px;
      }

      .address-details {
        @apply flex w-full items-center justify-center py-5;

        a {
          @apply pl-0 ml-0;
        }
      }

      .contact-information {
        @apply text-right relative flex flex-col items-end justify-end;

        a:first-of-type {
          line-height: 1.5em;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .content-container {
      .content {
        @apply flex-row items-end;

        .address-details {
          @apply items-end justify-start py-0;
          a {
            @apply pl-2 ml-8;
          }
        }
      }
    }
  }
}
</style>
