<template>
  <div class="get-notified-container">
    <div class="content-container">
      <div class="content">
        <div class="transparent-container">
          <h2>Stay in the Loop</h2>

          <Transition name="slide-fade" :duration="300" appear mode="out-in">
            <div class="step-one" v-if="step === 1">
              <p>
                Opt in for notifications of discounted Empty Flight Legs or Ride
                Share Opportunities through text message. Click the "Agree"
                button below to continue signing up.
              </p>
              <div class="inline-flex items-start checkbox-container">
                <label
                  class="relative flex items-center p-3 rounded-full cursor-pointer"
                  for="agree"
                  data-ripple-dark="true"
                >
                  <input
                    id="agree"
                    type="checkbox"
                    class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
                    v-model="agreed"
                  />
                  <div
                    class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        class="text-white"
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </label>
                <label class="mt-px font-light text-gray-700 cursor-auto">
                  By clicking the checkbox: "I agree that PVTAIR<sup>&reg;</sup>
                  may contact me via email, telephone, or text message at the
                  email address and phone numbers provided. I understand that
                  this consent is not required to make a purchase. You may reply
                  with STOP to opt-out at any time."
                </label>
              </div>

              <div class="button-container">
                <button
                  :class="[
                    {
                      disabled: !agreed,
                    },
                  ]"
                  :disabled="!agreed"
                  @click="step = 2"
                >
                  Continue
                </button>
              </div>
            </div>
            <div class="step-two" v-else>
              <p style="display: block; width: 100%"></p>
              <iframe
                src="GetNotifiedForm.html"
                ref="subscriptionIFrame"
                @load="resizeIframe"
              ></iframe>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GetNotified",
  data() {
    return {
      agreed: false,
      step: 1,
    };
  },
  mounted() {
    this.toggleShowPageTrue();
  },
  methods: {
    ...mapActions("page", ["toggleShowPageTrue"]),
    agree() {
      this.agreed = true;
    },
    resizeIframe() {
      setTimeout(() => {
        console.log("resizer called");
        let obj = this.$refs["subscriptionIFrame"];
        obj.style.height =
          obj.contentWindow.document.documentElement.scrollHeight + "px";

        obj.style.opacity = "1";
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.get-notified-container {
  @apply bg-no-repeat bg-cover flex items-center bg-gray-700;
  //min-height: 82vh;
  //background-image: url("@/assets/images/planes-on-the-field.jpg");

  .content-container {
    @apply w-full;
    .content {
      @apply w-full;
      .transparent-container {
        @apply w-full rounded-l px-6 py-6 my-14 mx-auto;
        background-color: rgba(255, 255, 255, 0.7);
        max-width: 650px;

        h2 {
          @apply text-center;
          font-size: 2.3em;
          line-height: 1em;
        }

        .step-one,
        .step-two {
          @apply flex flex-col justify-center;

          p {
            @apply text-center my-5;
            font-size: 1.2em;
            line-height: 1.4em;
          }

          .checkbox-container {
            color: #fff !important;
          }

          .button-container {
            @apply text-center;
            button {
              @apply border border-gray-300 rounded bg-green-600 px-2 text-gray-100;
              font-size: 1.2em;
              line-height: 1.4em;
              transition: all 0.3s ease-in;

              &.disabled {
                @apply bg-gray-400 cursor-no-drop;
              }
            }
          }

          iframe {
            @apply opacity-0;
            height: 250px;
            transition: all 0.7s ease-in;
          }
        }
      }
    }
  }
}
</style>
