<template>
  <div class="three-column-container">
    <div class="first-image">
      <LeftToRightFade animation-delay="0.6s" :threshold="1">
        <img
          src="@/assets/images/plane-with-open-door.jpg"
          alt="Plane with an Open Door Image"
        />
      </LeftToRightFade>
    </div>
    <div class="second-image">
      <FadeIn :threshold="1">
        <img
          src="@/assets/images/plane-seating.jpg"
          alt="Luxury Plane Seating Image"
        />
      </FadeIn>
    </div>
    <div class="third-image">
      <RightToLeftFade animation-delay="0.3s" :threshold="1">
        <img
          src="@/assets/images/plane-navigation-system.jpg"
          alt="Plane Navigation System Image"
        />
      </RightToLeftFade>
    </div>
  </div>
</template>

<script>
import FadeIn from "@/components/UI/Animations/FadeIn";
import RightToLeftFade from "@/components/UI/Animations/RightToLeftFade";
import LeftToRightFade from "@/components/UI/Animations/LeftToRightFade";
export default {
  name: "ThreeColumnImages",
  components: { LeftToRightFade, RightToLeftFade, FadeIn },
};
</script>

<style lang="scss" scoped>
.three-column-container {
  @apply flex flex-wrap w-full overflow-x-hidden;

  .first-image,
  .second-image,
  .third-image {
    @apply w-full;

    img {
      @apply w-full;
    }
  }

  @media screen and (min-width: 600px) {
    .first-image,
    .second-image,
    .third-image {
      @apply w-1/3;
    }
  }
}
</style>
