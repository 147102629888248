<template>
  <PageWrapper>
    <HeaderBlock v-if="viewName !== 'home'" />
    <router-view />
    <FooterBlock v-if="viewName !== 'home'" />
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/Template/PageWrapper";
import FooterBlock from "@/components/Template/FooterBlock.vue";
import HeaderBlock from "@/components/Template/HeaderBlock.vue";
export default {
  components: { HeaderBlock, FooterBlock, PageWrapper },
  computed: {
    viewName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss"></style>
