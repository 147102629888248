<template>
  <div class="button-container">
    <a :href="hyperLink" rel="nofollow">{{ linkText }}</a>
  </div>
</template>

<script>
export default {
  name: "ButtonLink",
  props: {
    linkText: {
      type: String,
      required: true,
    },
    hyperLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  a {
    @apply uppercase py-1 px-3 relative;
    font-family: FuturaT-Bold, sans-serif;
    border: 1px solid #3f3f3f;
    border-radius: 20px;
    transition: all 0.3s linear;

    &:hover {
      border-radius: 0;
      transform: scale(120%) !important;
    }
  }
}
</style>
