<template>
  <div class="hero-container">
    <div class="background-image-container">
      <MoveUpOnScrollDown
        :travel-percentage="27"
        :parent-height="containerHeight"
        :parent-offset-top="containerOffsetTop"
      >
        <img
          src="@/assets/images/planes-on-the-field.jpg"
          alt="Airplane on the runway"
        />
      </MoveUpOnScrollDown>
    </div>
    <div class="content-container">
      <div class="content">
        <FadeIn>
          <img
            :class="[{ loading: !showPage }, 'logo-image']"
            src="@/assets/images/pvt-air-logo.png"
            alt="Pvt Air Logo"
            ref="logo"
          />
        </FadeIn>
      </div>
    </div>
  </div>
</template>

<script>
import MoveUpOnScrollDown from "@/components/UI/Animations/MoveUpOnScrollDown";
import { mapState } from "vuex";
import FadeIn from "@/components/UI/Animations/FadeIn";
export default {
  name: "HeroComponent",
  components: { FadeIn, MoveUpOnScrollDown },
  data() {
    return {
      containerHeight: 0,
      containerOffsetTop: 0,
      styles: {
        top: "0",
      },
    };
  },
  computed: {
    ...mapState("page", {
      showPage: "showPage",
    }),
  },
  mounted() {
    this.containerHeight = this.$el.clientHeight;
    this.containerOffsetTop = this.$el.offsetTop;
    this.preloadImage();
  },
  methods: {
    preloadImage() {
      const imageLoader = document.createElement("img");

      imageLoader.onload = () => {
        this.$emit("loaded");
      };
      imageLoader.src = require("../assets/images/planes-on-the-field.jpg");
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-container {
  @apply relative overflow-hidden;
  min-height: 350px;

  .background-image-container {
    @apply absolute left-0 right-0;
    top: -20%;
    left: -60%;
    img {
      min-width: 200%;
    }
  }

  .content-container {
    @apply uppercase flex items-start justify-center;
    min-height: 300px;

    .content {
      @apply relative flex flex-col items-center;
      color: #d4a326;
      letter-spacing: 1px;
      font-family: Athelas-Bold, serif;

      img.logo-image {
        @apply relative pt-12 top-0;
        transition: all 0.6s ease-in;
        // Animates with the loading screen.
        z-index: 100;
        max-width: 175px;

        &.loading {
          transform: scale(119%) translateY(20vh);
        }
      }
    }
  }

  @media screen and (min-width: 575px) {
    min-height: 400px;
    .background-image-container {
      top: 10%;
      left: -5%;
      img {
        min-width: 155%;
      }
    }

    .content-container {
      min-height: 350px;
      .content {
        img.logo-image {
          @apply pt-20;
          max-width: 200px;
        }
      }
    }
  }
  /*************************************************/
  @media screen and (min-width: 900px) {
    min-height: 500px;
    .background-image-container {
      top: 10%;
      img {
        min-width: 122%;
      }
    }

    .content-container {
      min-height: 450px;
      .content {
        img.logo-image {
          @apply pt-24;
          max-width: 250px;
        }
      }
    }
  }
  /*************************************************/
  @media screen and (min-width: 1200px) {
    min-height: 650px;
    .background-image-container {
      top: 10%;
      img {
        min-width: 121%;
      }
    }

    .content-container {
      min-height: 600px;
      .content {
        img.logo-image {
          @apply max-w-full;
        }
      }
    }
  }
  /*************************************************/
  @media screen and (min-width: 1366px) {
    .background-image-container {
      top: 10%;
      img {
        min-width: 110%;
      }
    }
  }
  /*************************************************/
  @media screen and (min-width: 1800px) {
    .background-image-container {
      top: -20%;
      img {
        min-width: 110%;
      }
    }
  }
}
</style>
