<template>
  <div class="header-container">
    <div class="content-container">
      <div class="content">
        <div class="logo">
          <img src="@/assets/images/pvtair-footer-logo.png" alt="PvtAir Logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderBlock",
};
</script>

<style lang="scss" scoped>
.header-container {
  @apply bg-black py-6;

  .content-container {
    .content {
      @apply flex flex-col items-center;

      .logo img {
        min-width: 210px;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .content-container {
      .content {
        @apply flex-row items-end;
      }
    }
  }
}
</style>
