<template>
  <div class="home-view">
    <HeroComponent @loaded="assetsLoaded" />
    <OpenText>
      <template v-slot:header>Our Services</template>
      <template v-slot:content>
        PVTAIR<sup>&reg;</sup> offers one-way or roundtrip private charters
        within North America. We offer complimentary estimates for your next
        private flight. Whether it's a business trip or a weekend getaway, let
        us get you there on your schedule.
      </template>
      <template v-slot:buttonArea>
        <ButtonLink
          link-text="Contact Us"
          hyper-link="mailto: admin@pvtair.com"
        />
      </template>
    </OpenText>
    <GetNotified />
    <ThreeColumnImages />
    <OpenText>
      <template v-slot:header>Our History</template>
      <template v-slot:content>
        Felts Field Aviation was founded in 1976. Later EmpireAir, LLC was
        formed and continued to do business as Felts Field Aviation. In November
        of 2021, EmpireAir was acquired and in October of 2022 the business was
        rebranded to do business as PVTAIR<sup>&reg;</sup>. The prior and
        current ownership are both native Spokane community members. PVTAIR<sup
          >&reg;</sup
        >
        is committed to our PNW roots and proud to call Spokane home.
      </template>
    </OpenText>
    <div class="mission-container" ref="missionContainer">
      <OpenText class="open-text">
        <template v-slot:header>Our Mission</template>
        <template v-slot:content>
          With relentless attention to safety, and commitment to our
          customers,<br />
          we deliver practical, efficient, private charter aviation, connecting
          our customers<br />
          with the world around them. <br />
          <br />Our dedicated team will help you climb to new levels.
        </template>
      </OpenText>
    </div>

    <FooterBlock />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from "vuex";
import HeroComponent from "@/components/HeroComponent";
import OpenText from "@/components/OpenText";
import ButtonLink from "@/components/UI/ButtonLink";
import FooterBlock from "@/components/Template/FooterBlock";
import ThreeColumnImages from "@/components/ThreeColumnImages";
import GetNotified from "@/views/GetNotified.vue";

export default {
  name: "HomeView",
  components: {
    GetNotified,
    ThreeColumnImages,
    FooterBlock,
    ButtonLink,
    OpenText,
    HeroComponent,
  },
  data() {
    return { containerHeight: 0, containerOffsetTop: 0 };
  },
  mounted() {
    this.containerHeight = this.$refs.missionContainer.clientHeight;
    this.containerOffsetTop = this.$refs.missionContainer.offsetTop;
  },
  methods: {
    ...mapActions("page", ["toggleShowPageTrue"]),
    assetsLoaded() {
      setTimeout(() => {
        this.toggleShowPageTrue();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.home-view {
  .mission-container {
    @apply relative overflow-hidden bg-no-repeat bg-cover;
    background-image: url("@/assets/images/plane-on-the-strip.jpg");
    min-height: 750px;

    .open-text {
      @apply relative flex flex-col items-center;
      min-height: 750px;
    }
  }

  /*************************************************/
  @media screen and (min-width: 1800px) {
    .mission-container {
      min-height: 900px;

      .open-text {
        min-height: 900px;
      }
    }
  }
}
</style>
