const state = {
  showPage: true, //false,
};
const getters = {
  //
};
const mutations = {
  toggleShowPage(state) {
    state.showPage = !state.showPage;
  },
};
const actions = {
  toggleShowPage({ commit }) {
    commit("toggleShowPage");
  },
  toggleShowPageTrue({ commit, state }) {
    if (!state.showPage) {
      commit("toggleShowPage");
    }
  },
};
const modules = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules,
};
